function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        // Filter locations by search word
        const job_search_location_input = $('#job-search-location-input-id');
        job_search_location_input.on('keydown', function (e) {
            let filter, i, location_label, locations_div, locations;
            filter = job_search_location_input[0].value.toUpperCase();
            locations_div = $('.job-search-select-locations');
            locations = locations_div.children();

            for (i = 0; i < locations.length; i++) {
                location_label = locations[i].getElementsByClassName('location-name')[0].innerText
                if (location_label.toUpperCase().indexOf(filter) > -1) {
                    locations[i].style.display = '';
                } else {
                    locations[i].style.display = 'none';
                }
            }
        });
        let checkedValues = [];
        $('.job-search-select-locations input[type=checkbox]').on('click', function () {
            if ($(this).is(':checked')) {
                checkedValues.push($(this).val());
            } else {
                checkedValues.splice( $.inArray($(this).val(),checkedValues) ,1 );
            }
            $("#selected-locations").html('');
            $.each(checkedValues, function(index, value) {
                $("#selected-locations").append($("<li>").text(value));
            });
            $('.job-search-select-locations .location-option').children('input').each(function () {
                if ($(this).is(':checked')) {

                    $('#job-search-location-input-id').attr('placeholder', 'Valitut kaupungit');
                    return false;
                } else {
                    $('#job-search-location-input-id').attr('placeholder', 'Kaikki sijainnit');
                }
            });


            if ($(this)[0].checked) {
                $(this).closest('.location-option')[0].style.order = -1;
            } else {
                $(this).closest('.location-option')[0].style.order = 1;
            }
        });

    });


})(jQuery);
